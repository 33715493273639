<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">KYC Management</a></li>
    </ol>
    <h1 class="page-header">KYC MANAGEMENT</h1>
    <panel noButton="true" title="MANAGE KYC STATUS" variant="panel-default">
      <button
          v-if="$can.view($store.getters.getAuthUser, 'kyc-settings')"
          class="btn btn-black btn-sm mb-2 pull-right"
          type="button"
          @click.prevent="kycModal(null)"
      >
        Enable/Disable Kyc
      </button>

      <table class="table table-bordered">
        <thead>
        <tr>
          <th>#ID</th>
          <th>Role</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(kyc, index) in kycStatus"
            :key="index"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ kyc.role.name | unslug }}</td>
          <td>{{ kyc.status ? 'Enabled' : 'Disabled' }}</td>
          <td>
            <button
                class="btn btn-sm mr-2 btn-info"
                type="button"
                @click.prevent="kycModal(kyc)"
            >
              <i
                  class="fa fa-edit"
              /> Edit
            </button>
            <button
                class="btn btn-danger btn-sm"
                type="button"
                @click="deletekyc(kyc.role.id, index)"
            >
              <i
                  class="fa fa-trash"
              /> Delete
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </panel>
    <modal
        :show-modal="showModal"
        @close="showModal = false"
    >
      <template v-slot:header>
        <h5><span v-if="edit">Edit</span><span v-else>Create New</span> KYC</h5>
      </template>
      <form @submit.prevent="validateForm">
        <div class="row">
          <div class="col-md-12">
            <div
                :class="{ 'has-feedback has-error': errors.has('role')}"
                class="form-group"
            >
              <label>Select Role</label>
              <select
                  v-model="kyc.role.id"
                  v-validate="'required'"
                  class="form-control"
                  name="role"
              >
                <option
                    v-for="r in roles"
                    :key="r.id"
                    :value="r.id"
                >
                  {{ r.name | unslug }}
                </option>
              </select>
              <small
                  v-if="errors.has('role')"
                  class="help-block"
              >
                {{ errors.first('role') }}
              </small>
            </div>
            <div
                :class="{ 'has-feedback has-error': errors.has('status')}"
                class="form-group"
            >
              <label>Status</label>
              <select
                  v-model="kyc.status"
                  v-validate="'required'"
                  class="form-control"
                  name="status"
              >
                <option value="1">
                  Enable
                </option>
                <option value="0">
                  Disable
                </option>
              </select>
              <small
                  v-if="errors.has('status')"
                  class="help-block"
              >
                {{ errors.first('status') }}
              </small>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button
                  class="btn btn-primary pull-right"
                  type="submit"
              >
                <span v-if="edit">Update</span>
                <span v-else>Create</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roles: [],
      kyc: {
        role: {
          id: null,
          name: ''
        },
        status: null
      },
      kycStatus: {},
      showModal: false,
      edit: true
    }
  },
  mounted() {
    this.fetch();
    this.fetchRoles();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/kyc-status').then(response => {
        this.kycStatus = response.data;
        this.loading = false;
      });
    },
    fetchRoles() {
      this.$axios.get('/api/v1/roles').then(response => {
        this.roles = response.data;
      })
    },
    deletekyc(id, index) {
      this.$axios.post('/api/v1/kyc-status/delete', {role_id: id}).then(() => {
        this.kycStatus.splice(index, 1);
      });
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.edit ? this.editKyc() : this.createKyc();
        }
      });
    },
    kycModal(k) {
      if (k) {
        this.edit = true;
        this.kyc = k;
      } else {
        this.edit = false;
        this.kyc = {
          role: {
            id: null,
            name: ''
          },
          status: null
        };
      }
      this.showModal = true;
    },
    editKyc() {
      this.$axios.put('/api/v1/kyc-status', {
        'role_id': this.kyc.role.id,
        'status': this.kyc.status
      }).then(() => {
        this.showModal = false;
        this.$toastr.s('The kyc status has been updated', 'Success');
        this.fetch();
      });
    },
    createKyc() {
      this.$axios.post('/api/v1/kyc-status', {
        'role_id': this.kyc.role.id,
        'status': this.kyc.status
      }).then(() => {
        this.showModal = false;
        this.$toastr.s('The kyc status has been created', 'Success');
        this.fetch();
      });
    }
  }
}
</script>
